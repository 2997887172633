// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coming-soon-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: sans-serif;
    color: #fff;
    position: relative;
}
img{
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    /*width: 100px;*/
    z-index: -1;
    opacity: 0.2;
}

`, "",{"version":3,"sources":["webpack://./src/components/pages/coming/ComingSoonPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,gCAAgC;IAChC,SAAS;IACT,QAAQ;IACR,gBAAgB;IAChB,WAAW;IACX,YAAY;AAChB","sourcesContent":[".coming-soon-page {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    height: 100vh;\n    font-family: sans-serif;\n    color: #fff;\n    position: relative;\n}\nimg{\n    position: absolute;\n    transform: translate(-50%, -50%);\n    left: 50%;\n    top: 50%;\n    /*width: 100px;*/\n    z-index: -1;\n    opacity: 0.2;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
