 const en = {
  translation: {
      part1: 'English part1',
      HOME:"Home",
      ABOUT_US:"About us",
      BLOG:"blog",
      CONTACTS:"Contacts",
      COURSES:"Courses",
      EVENTS:"Events",
      TERMINATES:"Terminates"
  },
};

export default en